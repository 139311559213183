import { Component, ElementRef, AfterViewChecked, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { commonConstants } from '@shell_components/constants/commonConstants';
import { ReportsService } from '@core_services/report/reports.service';
import { AppService } from '@core_services/app.service';
import { PresetReportsComponent } from '@shell_components/reports/preset-reports/preset-reports.component';
import { SharedConstant, SharedService, ManageEmpGrpService } from '@ems/shared';
import { Dropdown } from 'primeng/dropdown';
import { Subject, Subscription } from 'rxjs';
import { AddlinkService } from '@ems/addlink';
import { EmtrPagination, EmtrPaginationValues } from 'projects/ems/emtr/src/public-api';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'app-rule-details',
  templateUrl: './rule-details.component.html',
  styleUrls: ['./rule-details.component.scss']
})
export class RuleDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {

  getShellConstant = ShellConstant;
  reportDetails: UntypedFormGroup;
  getCommConst = commonConstants;
  reportGroupName: any;
  reportRuleName: any;
  fromDate: any;
  toDate: any;
  parent: PresetReportsComponent;
  finalAppendedUrl: string;
  runReportFor: string;
  passReportName: string;
  ruleNames: any;
  ruleTypes: any;
  ruleStatus: any;
  appliesTo: any;
  outputFormat: any;
  dateError: any;
  endDateLessThanStartDate = '';
  public getOrgId: any;
  public showClientMsg: any = true;
  public reportName: any;
  allGroupsInfo = ShellConstant.reports.allGroupsInfo;
  empGroupDropDownOpts = [];
  currentOrgSubscription: Subscription;
  orgID: any;
  businessUnit: any;
  sharedConstant = SharedConstant;
  pageObject: EmtrPagination = EmtrPaginationValues;
  snapshotSelected = true;
  selectedOptionsOfRuleType = [];
  selectedOptionsOfSubType = [];
  selectedOptionsOfStatusType = [];
  dateRangeConstarint = '';
  searchInputUpdate = new Subject<any>();
  inititalLoad = true;
  @ViewChild('dropdown') groupDropdown: Dropdown;

  constructor(public addlinkService: AddlinkService, private appService: AppService, private fb: UntypedFormBuilder, private el: ElementRef, public reportService: ReportsService, private injector: Injector, private activatedRoute: ActivatedRoute, public sharedService: SharedService,
              public manageEmpGrpService: ManageEmpGrpService) {
    this.parent = this.injector.get(PresetReportsComponent);
    this.reportName = this.reportService.getReportName();
    this.ruleNames = [
      {label: this.getShellConstant.reports.RulesDetails.all, value: ''}];

    this.ruleTypes = [
      {
        name: this.getCommConst.rulesReportRuleType.restriction,
        value: this.getCommConst.rulesReportRuleType.restriction.toUpperCase()
      },
      {
        name: this.getCommConst.rulesReportRuleType.override,
        value: this.getCommConst.rulesReportRuleType.override.toUpperCase()
      },
      {
        name: this.getCommConst.rulesReportRuleType.watchList,
        value: this.getCommConst.rulesReportRuleType.watchList.toUpperCase()
      }];

    this.appliesTo = [
      {
        name: this.getShellConstant.reports.RulesDetails.individualSecurity,
        value: this.getShellConstant.reports.RulesDetails.individualSecurityCaps
      },
      {
        name: this.getShellConstant.reports.RulesDetails.securityType,
        value: this.getShellConstant.reports.RulesDetails.securityTypeCaps
      },
      {
        name: this.getShellConstant.reports.RulesDetails.fundFamilyTxt,
        value: this.getShellConstant.reports.RulesDetails.fundFamilyCaps
      }
    ];

    this.ruleStatus = [
      {
        name: this.getShellConstant.reports.RulesDetails.active,
        value: this.getShellConstant.reports.RulesDetails.active.toUpperCase(),
      },
      {
        name: this.getShellConstant.reports.RulesDetails.scheduled,
        value: this.getShellConstant.reports.RulesDetails.scheduled.toUpperCase(),
      },
      {
        name: this.getShellConstant.reports.RulesDetails.draft,
        value: this.getShellConstant.reports.RulesDetails.draft.toUpperCase(),
      },
      {
        name: this.getShellConstant.reports.RulesDetails.expired,
        value: this.getShellConstant.reports.RulesDetails.expired.toUpperCase(),
      }];
    this.sharedService.isValueObject = true;
   }

  ngOnInit() {
    this.reportDetails = this.fb.group({
      reportName: [{value: '', disabled: true}, Validators.required],
      dateTo: this.fb.group({
        date: [''],
      }),
      dateFrom: this.fb.group({
        date: [''],
      }),
      tradeSymbol: '',
      cusipSymbol: '',
      selectedRuleName: '',
      selectedRuleType: [this.selectedOptionsOfRuleType],
      selectedRuleStatus: [this.selectedOptionsOfStatusType],
      selectedAppliesTo: [this.selectedOptionsOfSubType],
      outputFormat: '',
      empGroupName: '',
      selectedButtonType: [this.getShellConstant.reports.RulesDetails.snapShot]
    });

    this.currentOrgSubscription = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.orgID = clientInfo && clientInfo.orgId;
        this.businessUnit = clientInfo && clientInfo.businessUnit;
        this.appService.setCurrentOrg(this.orgID);
        this.reportService.orgId = this.orgID;
        this.reportGroupName = '';
        this.pageObject.pageNo = 0;
        this.pageObject.pageSize = 15;
        this.pageObject.sortField = ['name,asc'];
        const param = {
          paramObj: this.pageObject
        };
        if (this.orgID !== 0) {
          this.getEmployeeGroupList(param);
          /*Freeze the global client selector when the report is selected*/
          this.reportService.freezeGlobalClientSelector(this.reportDetails);
        }
      }
    });

    this.reportService.makeFormDirty(this.reportDetails, this.parent);
    this.onChanges();
    this.sharedService.currentOrg.subscribe(clientInfo => {
      this.getOrgId = clientInfo;
    });
    this.reportService.reportsArray.subscribe((data: any) => {
      if (data !== null) {
        data.map((res) => {
          if (res.name === this.reportName) {
            res.accessType === 'CLIENT' ? this.getOrgId > 0 ? this.showClientMsg = false : this.showClientMsg = true : this.showClientMsg = false;
          }
        });
      }
    });

    this.ruleTypes.forEach((item) => this.selectedOptionsOfRuleType.push(item));
    this.ruleTypes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    this.reportDetails.get('selectedRuleType').patchValue(this.selectedOptionsOfRuleType);
    this.appliesTo.forEach((item) => this.selectedOptionsOfSubType.push(item));
    this.appliesTo.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    this.reportDetails.get('selectedAppliesTo').patchValue(this.selectedOptionsOfSubType);
    this.ruleStatus.forEach((item) => this.selectedOptionsOfStatusType.push(item));
    this.ruleStatus.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    this.reportDetails.get('selectedRuleStatus').patchValue(this.selectedOptionsOfStatusType);

    this.searchInputUpdate
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe((results) => {
        const param = {
          paramObj: this.pageObject,
          groupName: results ? results : ''
        };
        if (this.orgID !== undefined && this.orgID !== 0) {
          this.getEmployeeGroupList(param);
        }
      });
  }
  
  ngAfterViewChecked() {
    const elementRefRuleType = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[0];
    this.sharedService.setMultiSelectDefaultLabel(elementRefRuleType, this.reportDetails, 'selectedRuleType', this.ruleTypes.length);
    const elementRefSubType = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[1];
    this.sharedService.setMultiSelectDefaultLabel(elementRefSubType, this.reportDetails, 'selectedAppliesTo', this.appliesTo.length);
    const elementRefRuleStatus = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[2];
    this.sharedService.setMultiSelectDefaultLabel(elementRefRuleStatus, this.reportDetails, 'selectedRuleStatus', this.ruleStatus.length);
  }

  runReports() {
    const localUpdatedFormValues = this.reportDetails.getRawValue();
    const isRuleTypeAll = this.ruleTypes.every((val, index) => localUpdatedFormValues.selectedRuleType[index] && (val.value === localUpdatedFormValues.selectedRuleType[index].value));
    const isSubTypeAll = this.appliesTo.every((val, index) => localUpdatedFormValues.selectedAppliesTo[index] && (val.value === localUpdatedFormValues.selectedAppliesTo[index].value));
    const isRuleStatusAll = this.ruleStatus.every((val, index) => localUpdatedFormValues.selectedRuleStatus[index] && (val.value === localUpdatedFormValues.selectedRuleStatus[index].value));
    const localInput = {
      endDate: encodeURIComponent(this.reportService.formatDate(this.toDate)),
      startDate: encodeURIComponent(this.reportService.formatDate(this.fromDate)),
      ruleName: this.reportRuleName ? this.reportRuleName.trim() : '',
      ruleType: localUpdatedFormValues.selectedRuleType && localUpdatedFormValues.selectedRuleType.length > 0 ? (isRuleTypeAll ? localUpdatedFormValues.selectedRuleType = ['ALL'] : localUpdatedFormValues.selectedRuleType.map(item => item.value)) : ['ALL'],
      ruleStatus: localUpdatedFormValues.selectedRuleStatus && localUpdatedFormValues.selectedRuleStatus.length > 0 ? (isRuleStatusAll ? localUpdatedFormValues.selectedRuleStatus = ['ALL'] : localUpdatedFormValues.selectedRuleStatus.map(item => item.value)) : ['ALL'],
      ruleSubType: localUpdatedFormValues.selectedAppliesTo && localUpdatedFormValues.selectedAppliesTo.length > 0 ? (isSubTypeAll ? localUpdatedFormValues.selectedAppliesTo = ['ALL'] : localUpdatedFormValues.selectedAppliesTo.map(item => item.value)) : ['ALL'],
      symbol: this.reportDetails.get('tradeSymbol').value ? this.reportDetails.get('tradeSymbol').value.trim() : '',
      cusip: this.reportDetails.get('cusipSymbol').value ? this.reportDetails.get('cusipSymbol').value.trim() : '',
      orgId: this.reportService.orgId,
      clientName: this.reportService.clientName,
      clientId: this.reportService.clientId,
      groupName: (this.reportDetails.get('empGroupName').value) ? this.reportDetails.get('empGroupName').value : '',
    };

    if (this.snapshotSelected) {
      delete localInput.endDate;
      delete localInput.startDate;
    }
    let passReportName = this.getShellConstant.reports.RulesDetails.passReportName;
    let type = '';
    if (this.snapshotSelected) {
      this.reportService.selectedSnapshotReport = true;
      passReportName = this.getShellConstant.reports.RulesDetails.passReportNameSnapShot;
      type = 'snapshot';
    }
    this.reportService.executeReports(this.parent, localInput, this.reportService.selectedReport, this.getShellConstant.reports.RulesDetails.rulesDetails, this.getShellConstant.reports.RulesDetails.runReportFor,
      passReportName, this.appService, type);
  }

  onChanges() {
    this.reportService.formCharLimitErrorMessage(this.reportDetails, this.parent, 'cusipSymbol', 9, this.sharedConstant.textFieldErrorMsg.cusipShouldContain9Digits);
    this.reportDetails.get('tradeSymbol').valueChanges.subscribe(tradeSymbolValue => {
      this.reportService.invalidSymbol = tradeSymbolValue && this.reportDetails.get('tradeSymbol').invalid;
      this.reportService.disableReportField(this.reportDetails, tradeSymbolValue, 'cusipSymbol');
      this.parent.msgToast.pushMessage('warn', this.sharedConstant.textFieldErrorMsg.reportWarnMsg);
      this.parent.warnMsg = true;
      if (!tradeSymbolValue) {
        this.parent.msgToast.pushMessage('', '');
      }
    });
    this.reportDetails.get('cusipSymbol').valueChanges.subscribe(cusipSymbolValue => {
      this.reportService.invalidCusip = cusipSymbolValue && this.reportDetails.get('cusipSymbol').invalid;
      this.reportService.disableReportField(this.reportDetails, cusipSymbolValue, 'tradeSymbol');
      this.parent.msgToast.pushMessage('warn', this.sharedConstant.textFieldErrorMsg.reportWarnMsg);
      this.parent.warnMsg = true;
      if (!cusipSymbolValue) {
        this.parent.msgToast.pushMessage('', '');
      }
    });
  }

  setDate(event) {
    if (!this.snapshotSelected) {
      this.fromDate = event.fromDate;
      this.toDate = event.toDate;
      this.dateError = event.dateError;
    }
  }

  throwDateRangeError(event) {
    this.dateRangeConstarint = event;
    this.parent.throwDateRangeError(event);
  }

  calendarValidation(event) {
    this.parent.calendarValidation(event);
  }

  throwDateError(event) {
    if (!this.snapshotSelected) {
      this.endDateLessThanStartDate = event;
      this.parent.throwDateError(event);
    }
  }

  cancelReport() {
    this.reportService.cancelReport(this.reportDetails, this.parent);
  }

  getEmployeeGroupList(param) {
    const filterObj = {orgId : this.orgID, groupName: param.groupName, active: true};
    this.manageEmpGrpService.getEmployeeGrpOrPlanList(param.paramObj, filterObj).subscribe((value: any) => {
      this.empGroupDropDownOpts = [];
      this.empGroupDropDownOpts.unshift({label: this.allGroupsInfo.groupNameAll, value: this.allGroupsInfo.groupNameAll});
      value.data.content.forEach(empGroupName => {
        const data = {label: empGroupName.name, value: empGroupName.name};
        this.empGroupDropDownOpts.push(data);
      });
      const empGropName = this.reportDetails.get('empGroupName').value;
      if (empGropName) {
        const empIdx = this.empGroupDropDownOpts.findIndex(ele => ele.value === empGropName);
        if (empIdx === -1) {
          this.empGroupDropDownOpts.push({ label: empGropName, value: empGropName });
        }
      }
      if (this.inititalLoad) {
        this.inititalLoad = false;
        this.reportDetails.get('empGroupName').setValue(this.empGroupDropDownOpts[0].value);
        this.groupDropdown.value = this.empGroupDropDownOpts[0].value;
      } else {
        this.groupDropdown.value = this.reportDetails.get('empGroupName').value;
      }
    });
  }

  searchedText(e, data) {
    this.reportService.makeFormDirty(this.reportDetails, parent);
    if (data === this.allGroupsInfo.groupName) {
      this.reportGroupName = e.target.value;
      this.searchInputUpdate.next(this.reportGroupName);
    } else {
      this.reportRuleName = e.target.value;
    }
  }
  groupNameUpdated(e) {
    this.reportDetails.get('empGroupName').setValue(e || 'All');
    this.reportDetails.markAsDirty();
    this.reportDetails.updateValueAndValidity();
  }

  clearSearch(dropdown: Dropdown) {
    if (!this.reportRuleName && !this.reportGroupName) {
      dropdown.resetFilter();
    }
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.sharedService.isValueObject = false;
    this.reportService.invalidCusip = false;
    this.reportService.invalidSymbol = false;
    this.reportService.schErrorMsg.emit([]);
    this.parent.reportFormIsDirty = false;
  }


  editSnapshotHistory() {
    this.inititalLoad = true;
    this.groupDropdown.resetFilter();
    this.groupDropdown.value = '';
    this.reportDetails.get('tradeSymbol').setValue('');
    this.reportDetails.get('cusipSymbol').setValue('');
    this.reportDetails.get('empGroupName').setValue('');
    this.reportDetails.get('selectedRuleName').setValue('');
    this.reportDetails.get('selectedRuleType').patchValue(this.selectedOptionsOfRuleType);
    this.reportDetails.get('selectedAppliesTo').patchValue(this.selectedOptionsOfSubType);
    this.reportDetails.get('selectedRuleStatus').patchValue(this.selectedOptionsOfStatusType);
    this.getEmployeeGroupList({paramObj: this.pageObject});
    const value = this.reportDetails.getRawValue().selectedButtonType;
    if (value === this.getShellConstant.reports.RulesDetails.snapShot) {
      this.snapshotSelected = true;
      this.endDateLessThanStartDate = '';
      this.dateError = false;
      this.reportDetails.get('dateTo').get('date').setValidators(null);
      this.reportDetails.get('dateFrom').get('date').setValidators(null);
      this.parent.throwDateError('');
      this.parent.clearDateErrors();
    } else {
      this.snapshotSelected = false;
      this.reportDetails.get('dateTo').get('date').setValidators([Validators.required]);
      this.reportDetails.get('dateFrom').get('date').setValidators([Validators.required]);
    }

    this.reportDetails.get('dateTo').get('date').updateValueAndValidity();
    this.reportDetails.get('dateFrom').get('date').updateValueAndValidity();
  }
}
