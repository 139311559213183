<div>

  <ems-toast-message *ngIf="(!(sharedService.errorMessage | async) || showToast)" #toastMessage></ems-toast-message>
  <ems-confirm-message #confirmationMessage></ems-confirm-message>

  <sdps-notification type="error" class="sdps-m-top_none" *ngIf="showSchError">
    <div class="sdps-notification__text">
      <p>Please correct following error.</p>
      <ul class="sdps-navigation--horizontal__list error-list">
        <li *ngFor='let msg of schErrorMsg' [innerHTML]="msg"></li>
      </ul>
    </div>
  </sdps-notification>

  <h2 class="page-title sdps-p-bottom_large sdps-display-inline-block" [innerHTML]="getSharedConstant[businessUnit].empParticipantSubtabName"></h2>

  <span class="sdps-m-horizontal_large client-selection-indicator" *ngIf="clientName !== null"
        [ngClass]="this.externalAccess ? 'external-access' : (sharedService.clientInformation | async)?.businessUnit | lowercase"
        [innerHTML]="clientName"></span>

</div>

          <div class="sdps-clearfix">
              <div *ngIf="employees && employees.length > 0">
                  <button type="button" class="buttons sdps-pull-left sdps-m-top_small sdps-m-left_small" (click)="uploadEmployeeList()"
                          routerLink="/ems/emtr/manage-employees/emp-bulk-upload" routerLinkActive="active"
                          [innerHTML]="getSharedConstant[businessUnit].bulkUploadManageEmployeesOrParticipants"
                  *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.addLinkAppAccess.manageEmployee.uuid, '', '')"></button>
                  <ul class="sdps-pull-left sdps-list--inline grid-header-icon sdps-hide">
                      <li>
                          <a>
                              <em class="pi pi-paperclip history-icon"></em>
                          </a>
                          <a>
                              <span [innerHTML]="getSharedConstant.manageEmployeeLabels.delink"></span>
                          </a>
                      </li>
                  </ul>
                  <ems-table-record-count [count]="totalRecords" (emitter)="receiveDataFromChild($event);"
                      *ngIf="employees" [selectedCount]="" [empIds]='' [showHeaderIcons]="true">
                  </ems-table-record-count>
              </div>

              <ems-view-history #viewHistory [historyId]="empHistory.historyId" [getOrgId]="getOrgId" [ruleSubType]="empHistory.empSubType" [historyTitle]="empHistory.empName" [historyData]="empHistory.empHistoryData"
              [totalElements]="empHistory.historyRecords" [historyMessage]='empHistory.historyMessage' (paginateHistoryList)="getHistoryPagination(empHistory.historyId,$event)"></ems-view-history>
              <form [formGroup]="manageEmpForm" class="manageEmp">
                    <p-table [value]="employees" [columns]="empAccTableCols" class="common-data-table" autoLayout="true" 
                    metaKeySelection=true dataKey="empId" #dt [lazy]="true" sortMode="multiple" [multiSortMeta]="multiSortMeta" (onLazyLoad)="customSort($event)" [customSort]="true">

                    <ng-template pTemplate="header" let-columns>
                        <tr class="filter-header-input">
                          <th class="table-checkbox-selection filter-icon">
                            <a (click)="sharedService.gutTracking(filterGutTrackValue); toggleFunction()" class="cursor">
                                <em class="pi pi-filter"></em>
                            </a>
                          </th>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="(col.field==='actions' || col.field === 'ruleIds')" [ngClass]="'w-10-per'">
                              <span [ngClass]="{'sdps-display-inline-block' : col.field === 'masterSubTypes'}" [innerHTML]="col.header"></span>
                              <p-sortIcon [field]="col.field"
                                                   *ngIf="(col.field !== 'actions' && col.field !== 'ruleIds')"></p-sortIcon>
                            </th>
                        </tr>
                        <tr *ngIf="toggle" class="filtered-row-input">
                          <th class="no-right-left-padding"></th>
                          <th class="sdps-p-left_small" *ngFor="let col of columns ;let i = index;">
                            <input pInputText formControlName="empId" class="w-100-per" type="text" name="empIdFilter" [maxLength]="35"
                                   *ngIf="col.field === 'empId'" emsEmployeeIdPatterncheck (keydown.enter)="!(errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                                   [ngClass]="{'invalidField': (filterBtnDisableForEmpId || invalidEmpId)}">
                            <input pInputText formControlName="subAcctNum" class="w-100-per" type="text" name="acctNumFilter" (keydown.enter)="!(errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                                   *ngIf="col.field === 'subAcctNum'" [maxLength]="8"
                                   [ngClass]="{'invalidField': isAccNumInvalid}"
                                   onkeypress="return (event.which >= 48 && event.which <= 57)">
                            <input pInputText formControlName="firstName"  class="w-100-per" type="text" name="firstNameFilter" (keydown.enter)="!(errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                                   *ngIf="col.field === 'firstName' && businessUnit !== getSharedConstant.rbsBUText" [maxLength]="30"
                                   [ngClass]="{'invalidField': (filterBtnDisableForFN || invalidFN)}">
                            <input pInputText formControlName="lastName" class="w-100-per" type="text" name="lastNameFilter" (keydown.enter)="!(errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                                   *ngIf="col.field === 'lastName' && businessUnit !== getSharedConstant.rbsBUText" [maxLength]="30"
                                   [ngClass]="{'invalidField': (filterBtnDisableForLN || invalidLN)}">
                            <input pInputText formControlName="acctHolderFn" class="w-100-per" type="text" name="acctHolderFNFilter" (keydown.enter)="!(errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                                   *ngIf="col.field === 'acctHolderFN' && businessUnit !== getSharedConstant.rbsBUText" [maxLength]="30"
                                   [ngClass]="{'invalidField': (filterBtnDisableForacctHolderFN || invalidacctHolderFN)}">
                            <input pInputText formControlName="acctHolderLn" class="w-100-per" type="text" name="acctHolderLNFilter" (keydown.enter)="!(errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                                   *ngIf="col.field === 'acctHolderLN' && businessUnit !== getSharedConstant.rbsBUText" [maxLength]="30"
                                   [ngClass]="{'invalidField': (filterBtnDisableForacctHolderLN || invalidacctHolderLN)}">
                                   <input pInputText formControlName="firstName" emsNamePatternCheck class="w-100-per" type="text" name="firstNameFilter" (keydown.enter)="!(errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                                   *ngIf="col.field === 'firstName' && businessUnit === getSharedConstant.rbsBUText" [maxLength]="30"
                                   [ngClass]="{'invalidField': (filterBtnDisableForFN || invalidFN)}">
                            <input pInputText formControlName="lastName" emsNamePatternCheck class="w-100-per" type="text" name="lastNameFilter" (keydown.enter)="!(errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                                   *ngIf="col.field === 'lastName' && businessUnit === getSharedConstant.rbsBUText" [maxLength]="30"
                                   [ngClass]="{'invalidField': (filterBtnDisableForLN || invalidLN)}">
                            <input pInputText formControlName="acctHolderFn" emsNamePatternCheck class="w-100-per" type="text" name="acctHolderFNFilter" (keydown.enter)="!(errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                                   *ngIf="col.field === 'acctHolderFN' && businessUnit === getSharedConstant.rbsBUText" [maxLength]="30"
                                   [ngClass]="{'invalidField': (filterBtnDisableForacctHolderFN || invalidacctHolderFN)}">
                            <input pInputText formControlName="acctHolderLn" emsNamePatternCheck class="w-100-per" type="text" name="acctHolderLNFilter" (keydown.enter)="!(errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid) ? applyFilter() : ''" onkeydown="return (event.keyCode !== 13);"
                                   *ngIf="col.field === 'acctHolderLN' && businessUnit === getSharedConstant.rbsBUText" [maxLength]="30"
                                   [ngClass]="{'invalidField': (filterBtnDisableForacctHolderLN || invalidacctHolderLN)}">
                                   <div *ngIf="col.field === 'actions'" class="sdps-p-right_none display-flex">
                                <button class="buttons button-filter" [disabled]="(!manageEmpForm.dirty && !hasGrpOrPlanVal && !isSortingChanged)"
                                    [innerHTML]="getSharedConstant.filterButton.clearAllButton"
                                    (click)="sharedService.selectedGroupAndPlanNameInEmpGroup = '';filterClicked? clickedClearButton(isSortingChanged) : resetForm(isSortingChanged); messageService.clear()"></button>
                                <button class="buttons button-save button-filter" (click)="applyFilter()"
                                    [disabled]="errMsg.length > 0 || manageEmpForm.invalid || isAccNumInvalid"
                                    [innerHTML]="getSharedConstant.filterButton.applyFilterButton"></button>
                            </div>
                          </th>
                        </tr>
                        <tr *ngIf="toggle" class="filtered-row-input">
                          <th class="no-right-left-padding"></th>
                          <th [attr.colspan]="columns.length" class="sdps-p-around_none">
                            <ems-manage-employees-sub-header (hasGroupPlanValue)="hasGrpOrPlanVal=$event" (subHeaderFormObj)="applyFilter()">
                            </ems-manage-employees-sub-header>
                          </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr>
                            <td [attr.colspan]="columns.length + 2" class="sdps-text-center sdps-font-weight-bold">
                                {{getSharedConstant.noRecords}}
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                          <td class="table-checkbox-selection filter-icon no-right-left-padding"></td>
                          <ng-container *ngFor="let col of columns; let i = index">
                            <td *ngIf="col.field !== 'actions'" [ngClass]="{'no-right-left-padding': col.field === 'status'}">
                                <span *ngIf="col.field === 'empId'">{{rowData[col.field]}}</span>
                                <span *ngIf="col.field === 'firstName' || col.field === 'acctHolderFN'">{{rowData[col.field]}}</span>
                                <span *ngIf="col.field === 'lastName'">{{rowData[col.field]}}</span>
                                <span *ngIf="col.field === 'masterSubTypes'">{{rowData[col.field]}}</span>
                                <span *ngIf="col.field === 'subAcctNum'">
                                    {{rowData[col.field]}}
                                </span>
                                <span *ngIf="col.field === 'status'"><span class="status"
                                        [ngClass]="rowData['active'] === false ? 'inactive' : 'active'"></span> 
                                        <span class="sdps-p-left_small" [innerHTML]="rowData['active'] === false ? 'Inactive' : 'Active'"></span></span>
                                <span *ngIf="col.field === 'ruleIds'"
                                    class="sdps-p-around_xx-small" title="{{rowData[col.field] && rowData[col.field].length}}">{{rowData[col.field] && rowData[col.field].length}}
                                    <span *ngIf="!rowData[col.field]" title="{{getSharedConstant.defaultValue}}">
                                        {{getSharedConstant.defaultValue}}
                                    </span>
                                </span>
                              <span *ngIf="col.field==='updTs'" class="date-alignment" [innerHTML]="(rowData[col.field]) ? moment(rowData[col.field]).format('MM/DD/YYYY') : ''"></span>
                                <span *ngIf="col.field === 'acctHolderLN'">{{rowData[col.field]}}</span>
                            </td>
                            </ng-container>
                           <td>
                            <div class="action-menu-dropdown">
                              <button type="button" class="cursor sdps-p-around_xx-small"
                                      (click)="showActionMenu=true; empId=rowData['empId']; subAcctNum=rowData['subAcctNum']; formData(rowData);"
                                      [ngClass]="{'active-menu':(subAcctNum===rowData['subAcctNum'] && empId===rowData['empId'])}">
                                <em class="pi pi-ellipsis-h sdps-p-around_xx-small"></em>
                              </button>
                              <p-menu [model]="actionMenu"
                                      *ngIf="(subAcctNum===rowData['subAcctNum'] && empId===rowData['empId'])"
                                      class="menu-dropdown" (click)="onActionMenuClick($event, rowData)"></p-menu>
                            </div>
                          </td>
                        </tr>
                    </ng-template>
                </p-table>
              </form>

              <p-paginator [rows]="noOfRecords" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"
                  class="common-pagination" #p></p-paginator>
          </div>
