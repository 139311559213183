<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div class="heading-font sdps-p-bottom_small sdps-display-inline"
     [innerHTML]="getConstant.manageEmployeeGroups.empGroupStep3.reviewAndSave"></div>

<!-- Group Name and Description -->
<ems-accordion label={{getSharedConstant[businessUnit].nameAndDescription}} [editable]="showActionItems"
               class="disabled-button-icon" [disabledButton]="true" [toolTip]="true" [message]="toolTipMessage">
  <div class="sdps-p-around_large select-rule-type">
    <form [formGroup]="reviewEmpGrpRuleForm">
      <div class="sdps-grid-container sdps-wrap sdps-m-around_none">
        <div class="sdps-row">
          <div class="rule-type-textbox sdps-p-around_medium col-3"
               [ngClass]="{'invalidField': ((reviewEmpGrpRuleForm.get('groupName').value && (reviewEmpGrpRuleForm.get('groupName').invalid)|| groupNameExists)), 'input-disabled': !showActionItems}">
            <span class="p-float-label">
              <input *ngIf= "this.businessUnit === 'DBS' || this.businessUnit === 'SPS'" #focusName type="text" name="groupName" [maxlength]=100 formControlName="groupName"
                     (ngModelChange)="editGroupNameAndDescription()" emsAlphaNumericSpecialCharacterValidation
                     pInputText/>
              <input *ngIf= "this.businessUnit === 'RBS' || this.businessUnit===this.getSharedConstant.manageMasterGroups.groupTypeMGP" #focusName type="text" name="groupName" [maxlength]=100 formControlName="groupName"
                     (ngModelChange)="editGroupNameAndDescription()"
                     pInputText/>
              <label>{{getSharedConstant[businessUnit].name}}
                <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
              </label>
            </span>
          </div>
          <div class="col-9 sdps-p-left_xx-large sdps-flex" *ngIf="is10b5Client">
            <div class="sdps-p-top_medium">
              <p-checkbox class="emtr-checkbox" label="10b5-1" formControlName="enable10b5Checkbox"
                          (onChange)="validate10b5()" (ngModelChange)="editGroupNameAndDescription()"
                          [disabled]="isEditMode" binary="true"></p-checkbox>
            </div>
            <div class="sdps-display-inline-block sdps-m-left_xx-large rule-type-textbox sdps-p-around_medium"
                 [ngClass]="{'invalidField': (reviewEmpGrpRuleForm.get('planId').value && reviewEmpGrpRuleForm.get('planId').invalid), 'input-disabled': isEditMode}"
                 *ngIf="reviewEmpGrpRuleForm.get('enable10b5Checkbox').value">
              <span class="p-float-label">
                <input type="text" [attr.disabled]="isEditMode ? true : null" name="planId" formControlName="planId"
                       (ngModelChange)="editGroupNameAndDescription()" (blur)="removeLeadingZeroes()" emsNumberPatterncheck pInputText/>
                <label>{{getConstant.manageEmployeeGroups.empGroup10b5Msg.planIdTxt}}
                  <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
                </label>
              </span>
            </div>
          </div>
        </div>
        <p class="col-3 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
           *ngIf="reviewEmpGrpRuleForm.get('groupName').value && reviewEmpGrpRuleForm.get('groupName').value.trim().length>90">{{getConstant.rulesConstant.characterLimit}}
          <span class="character-limit">{{reviewEmpGrpRuleForm.get('groupName').value.trim().length}}/100</span>
        </p>

        <div class="sdps-row sdps-p-top_large">
          <div class="rule-type-textbox sdps-p-around_medium col-6" [ngClass]="{'input-disabled': !showActionItems}">
            <span class="p-float-label">
              <textarea class="sdps-p-vertical_x-small" rows="5" maxlength="300" formControlName="description"
                        (ngModelChange)="editGroupNameAndDescription()" pInputTextarea></textarea>
              <label>{{getSharedConstant[businessUnit].description}}</label>
            </span>
          </div>
        </div>
        <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
           *ngIf="reviewEmpGrpRuleForm.get('description').value && reviewEmpGrpRuleForm.get('description').value.length > 270">{{getConstant.rulesConstant.characterLimit}}
          <span class="character-limit">{{reviewEmpGrpRuleForm.get('description').value.length}}/300</span>
        </p>
      </div>
    </form>
  </div>
</ems-accordion>

<!-- Added/Removed employees/participants -->
<ems-accordion *ngIf="showEmployeePanel && this.sharedService.groupType !== this.getSharedConstant.manageMasterGroups.groupTypeMGP" [label]="getSharedConstant[businessUnit].employeeOrParticipantAddedOrRemoved"
               [editable]="showActionItems" (editPanel)="goToPreviousStep('../step2')">
  <ems-table-record-count [count]="" (emitter)="empPanel.reset();receiveDataFromChildPanel($event)"
                          [selectedCount]="" [empIds]="" [showHeaderIcons]="false"></ems-table-record-count>
  <p-table #empPanel [columns]="cols" [value]="showUpdatedData" [paginator]="true" [rows]="noOfRecordsPanel"
               [totalRecords]="showUpdatedData && showUpdatedData.length" class="common-data-table" sortField="empId" [sortOrder]="-1">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns"><span [innerHTML]="col.header"></span></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body"  let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns; let i = index">
          <!--<span
             *ngIf="i === 0 && businessUnit !=='RBS' && (rowNode.parent.children[0] === rowNode.node) && rowNode.parent.data[col.field] !== 0 && (rowNode.parent.data[col.field].indexOf('null') === -1)">{{rowNode.parent.data[col.field]}}</span> -->
          <span
            *ngIf="i === 0 && businessUnit !=='RBS' && rowData[col.field]">{{rowData[col.field]}}</span>
          <span *ngIf="(i === 0 && businessUnit === 'RBS') || (i === 1 && businessUnit !=='RBS')"
                [ngClass]="rowData.selectedEmployee === true ? 'addedEmp' : 'removedEmp'">{{rowData[col.field]}}</span>
          <span *ngIf="col.field === 'firstName' || col.field === 'lastName'">{{rowData[col.field]}}</span>
          <span *ngIf="col.field === 'acctHolderName'">{{rowData['acctHolderLN']}} {{rowData['acctHolderFN']}}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="businessUnit === 'RBS' ? 4 : 5" class="sdps-text-center sdps-font-weight-bold" [innerHTML]="getSharedConstant.noRecords"></td>
      </tr>
    </ng-template>
  </p-table>
</ems-accordion>

<!-- Selected employees/participants - Edit flow when no changes is made to employees/participants selection -->
<ems-accordion *ngIf="!showEmployeePanel && reviewRuleGroupData.mode === 'EDIT' && this.sharedService.groupType !== this.getSharedConstant.manageMasterGroups.groupTypeMGP " [editable]="showActionItems"
               [label]="getSharedConstant[businessUnit].employeeOrParticipant"
               (editPanel)="goToPreviousStep('../step2')">
  <ems-table-record-count [count]="" (emitter)="dtt.reset();receiveDataFromChild($event)" [selectedCount]="" [empIds]=""
                          [showHeaderIcons]="false"></ems-table-record-count>
  <p-table #dtt [columns]="cols" [value]="verifyData" [paginator]="!isEditMode" [rows]="noOfRecordsPanel"
    [totalRecords]="verifyData && verifyData.length" class="common-data-table" sortField="empId"
    [sortOrder]="-1">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns"><span [innerHTML]="col.header"></span></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns; let i = index">
          <span *ngIf="i === 0 && businessUnit !=='RBS' && rowData[col.field]">{{rowData[col.field]}}</span>
          <span *ngIf="(i === 0 && businessUnit === 'RBS') || (i === 1 && businessUnit !=='RBS')">{{rowData[col.field]}}</span>
          <span *ngIf="col.field === 'firstName' || col.field === 'lastName'">{{rowData[col.field]}}</span>
          <span *ngIf="col.field === 'acctHolderName'">{{rowData['acctHolderLN']}} {{rowData['acctHolderFN']}}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="businessUnit === 'RBS' ? 4 : 5" class="sdps-text-center sdps-font-weight-bold" [innerHTML]="getSharedConstant.noRecords"></td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator #p *ngIf="isEditMode" [rows]="pageObject.pageSize" [totalRecords]="totalRecords"
               (onPageChange)="paginate($event)" class="common-pagination"></p-paginator>
</ems-accordion>

<!-- Added/Removed Master accounts - Manage master group -->
<ems-accordion *ngIf="showEmployeePanel && this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP"
               [label]="getSharedConstant[businessUnit].employeeOrParticipantAddedOrRemoved" [editable]="showActionItems"
               (editPanel)="goToPreviousStep('../step2')">
  <ems-table-record-count [count]="" (emitter)="mstrAcct.reset();receiveDataFromChildPanel($event)" [selectedCount]="" [empIds]=""
                          [showHeaderIcons]="false"></ems-table-record-count>

  <p-table #mstrAcct [value]="addedOrRemoveMasterAcctList" [columns]="cols" [rows]="noOfRecordsPanel" [paginator]="true"
    [totalRecords]="addedOrRemoveMasterAcctList && addedOrRemoveMasterAcctList.length" class="common-data-table">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns"><span [innerHTML]="col.header"></span></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <span *ngIf="col.field === 'firstName'">{{rowData[col.field]}}</span>
          <span *ngIf="col.field === 'subAcctNum'"
                [ngClass]="rowData['selectedAcct'] === true ? 'addedEmp' : 'removedEmp'">{{rowData[col.field]}}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ems-accordion>

<!-- Selected Master accounts - Edit flow when no changes is made to master accounts selection - Manage master group -->
<ems-accordion *ngIf="!showEmployeePanel && reviewRuleGroupData.mode === 'EDIT' && this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP"
               [label]="getSharedConstant[businessUnit].employeeOrParticipant" [editable]="showActionItems"
               (editPanel)="goToPreviousStep('../step2')">
  <ems-table-record-count [count]="" (emitter)="dtt.reset();receiveDataFromChild($event)" [selectedCount]="" [empIds]=""
                          [showHeaderIcons]="false"></ems-table-record-count>

  <p-table #dtt [value]="clientList" [columns]="cols" [rows]="noOfRecords" [paginator]="!isEditMode"
           [totalRecords]="clientList && clientList.length" class="common-data-table">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns"><span [innerHTML]="col.header"></span></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <span>{{rowData[col.field]}}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator #p *ngIf="isEditMode" [rows]="pageObject.pageSize" [totalRecords]="totalRecords"
               (onPageChange)="paginate($event)" class="common-pagination"></p-paginator>
</ems-accordion>

<ems-step-wizard-buttons [disableBtn]="reviewEmpGrpRuleForm?(reviewEmpGrpRuleForm.invalid || groupNameExists || (reviewEmpGrpRuleForm.get('groupName').value && reviewEmpGrpRuleForm.get('groupName').value.trim() ==='')):''"
                         [showCancelBtn]=showActionItems (closeBtn)="cancelGroup()" [showRevertBtn]="showActionItems" (save)="saveGroup()"
                         [revertDisable]="revertDisable" (revert)="revertChanges()" [showSaveBtn]="showActionItems"
                         [showCloseBtn]="!showActionItems"
                         [showPreviousBtn]="showActionItems" (goToPrevious)="goToPreviousStep('../step2')"></ems-step-wizard-buttons>
