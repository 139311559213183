import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {ConfigUrlService, SharedService} from '@ems/shared';

@Injectable()
export class DelinkService {

  public env: any;
  configURL = this.configService.setApplicationUrl();

  constructor(@Inject('env') public e, private http: HttpClient, public configService: ConfigUrlService, public sharedService: SharedService) {
    this.env = e;
  }

  public getDelinkMasterAccounts(orgId, acctNumber): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.subAccount, acctNumber, schwabClientHeader);
    const url = this.configURL.bffAddlinkClientMasterAccountEndPointV1 + '&Schwab-Client-Ids=' + schwabClientHeader;
    return this.http.get(url);
  }

  public saveMultiDelinkLinkage(requestObject: any, orgId: any, jobId: string, fileAsBytes: any): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    const url = this.configURL.bffAddlinkRequestEndPointV1;

    const selectedFileAsBytes = [];

    const remarks: any = [];

    let hideFromClient = 'N';
    if (requestObject.doNotShowRemarkToClient) {
      hideFromClient = 'Y';
    }

    remarks.push({ remark: requestObject.remarks, hideFromClient });

    const masterAccountNbrList = [];
    const masterAccountNbrTypeList = [];

    const masterAccounts: any = requestObject.masterAccounts;
    for (const masterAccount of masterAccounts) {
      masterAccountNbrList.push(masterAccount.value);
      if (masterAccount.accountType.includes('SL')) {
        masterAccountNbrTypeList.push('SL');
      } else {
        masterAccountNbrTypeList.push('FA');
      }
    }

    const body = {
      action: 'DELINK',
      orgId,
      authAgentId: requestObject.authAgent.length > 0 ? requestObject.authAgent[0].value : '',
      masterAccountNbrList,
      masterAccountNbrTypeList,
      acctNumber: requestObject.accountNumber,
      remarks,
      jobId,
      source: 'ADDLink(DBS)',
      file: selectedFileAsBytes,
    };

    if (orgId && orgId !== 0) {
      return this.http.post(url + '&Schwab-Client-Ids=' + schwabClientHeader, body);
    } else {
      return this.http.post(url, body);
    }
  }

}
