import { Component, OnInit, Input, HostListener, Output, EventEmitter, ElementRef} from '@angular/core';
import { DefaultSecurityRecords, AllSecurityRecords } from '../../models/custom-multiselect.model';
import {emtrConstant} from '../../constants/emtrConstants';
import {RuleService} from '../../services/rule-service/rule.service';

@Component({
  selector: 'tr-custom-multiselect',
  templateUrl: './custom-multiselect.component.html'
})
export class CustomMultiselectComponent implements OnInit {

  getConstant = emtrConstant;
    showDropDown = false;
    selectAllCheckbox: boolean;
    selectedCategories: any = [];
    showSelectedArray: any = [];
    securityDropDownData: any = [];
    highlightAll = false;
    defaultSecurityRecords: string[] = DefaultSecurityRecords.map(s => s.code);
    defaultAllRecords: any = AllSecurityRecords;
    checkAllArray: any = [];
    sharedEnv: any;
    /*Emitting the selected Records to Parent Component */
    @Output() selectedRecords: EventEmitter<string> = new EventEmitter<string>();
    @Input() selectedValues: any[] = [];
    @Output() emitSelectedvalues: EventEmitter<string> = new EventEmitter<string>();
    @Input() isDisabled: boolean;

    constructor(private ef: ElementRef, private ruleService: RuleService) {

    }
    @HostListener('document:click', ['$event'])
    onClick(event) { /* Capture the Click Event outside the function */
        if (!this.ef.nativeElement.contains(event.target)) {
            this.showDropDown = false;
        }
    }

    ngOnInit() {
        if (this.selectedValues && this.selectedValues.length > 0) {
            this.defaultSecurityRecords = this.selectedValues.map(s => s.code);
        }
        this.ruleService.getAssettypes().subscribe((datas: any) => {
            this.securityDropDownData = datas.data;
            this.securityDropDownData.sort((a, b) => (a.desc > b.desc) ? 1 : ((b.desc > a.desc) ? -1 : 0));
            /* Pushing Whole array without Options for select all checkbox checked/unchecked Functionality*/
            this.checkAllArray = this.securityDropDownData;
            this.selectedCategories = [];
            /* Default Selected Security */
            this.insertDefaultRecords('', '');
            this.showSelectedArray = this.selectedCategories;
            const sortedAllArray = this.removeOptionsInArray(this.checkAllArray).sort().toString();
            const sortedDefaultrecord = [...this.defaultSecurityRecords].sort();
            this.enableSelectAllBox(sortedAllArray, sortedDefaultrecord);
            this.selectedRecords.emit(this.showSelectedArray);
            this.emitSelectedvalues.emit(this.selectedCategories);
        });
    }

    enableSelectAllBox(sortedAllArray, sortedDefaultrecord) {
      if (this.checkAllArray.sort().toString() === this.selectedCategories.sort().toString()) {
        this.selectAllCheckbox = true;
        this.addAllToArray();
      } else if (sortedAllArray === sortedDefaultrecord.toString()) {
        this.selectAllCheckbox = true;
      }
    }

    selectSecurity() {     /* Clicking Div to Open and close the DropDown */
        return this.showDropDown = !this.showDropDown;
    }

    /* DropDown clicking All Functionality */
    selectedAll() {
        if (this.selectAllCheckbox) {     /* Select-All Check Scenario */
            let optionObj;
            const codes = this.selectedCategories.length ? this.selectedCategories.map(a => a.code) : [];
            if (codes.indexOf('OPT') !== -1) {   /* Check if Options Already Exists */
                optionObj = this.selectedCategories[codes.indexOf('OPT')];
            }
            this.insertDefaultRecords(this.getConstant.rulesConstant.assetType.bond, this.getConstant.rulesConstant.assetType.other);
            this.showSelectedArray = [];

            this.selectedCategories.forEach(element => {
                this.showSelectedArray.push(element);
            });
            if (optionObj) {
                this.selectedCategories.push(optionObj);
                this.addAllToArray();
            }
            this.highlightAll = true;
        } else {
            /* Select-All UnCheck Scenario */
            this.unCheckSelectAll();
            this.highlightAll = false;
        }
        /* Emitting the Selected Array to Parent Component */
        this.selectedRecords.emit(this.showSelectedArray);
        this.emitSelectedvalues.emit(this.selectedCategories);
    }

    /* Select-All UnCheck Scenario */
    unCheckSelectAll() {
        let optionObj;
        const codes = this.selectedCategories.length ? this.selectedCategories.map(a => a.code) : [];
        if (codes.indexOf('OPT') !== -1) {  /* Check if Options Already Exists */
            optionObj = this.selectedCategories[codes.indexOf('OPT')];
        }
        this.selectedCategories = [];
        this.showSelectedArray = [];
        if (optionObj) {
            this.selectedCategories.push(optionObj);
            this.showSelectedArray.push(optionObj);
        }
        this.emitSelectedvalues.emit(this.selectedCategories);
    }
    /* Checked/Unchecked Checkbox in DropDown Functionality */
    checkboxChecked(data) {
        /* Pushing Whole array without Options for select all checkbox checked/unchecked Functionality*/
        /* Verifying Whole Array and SelectedCategories array both are same*/
        if (this.checkAllArray.sort().toString() === this.selectedCategories.sort().toString()) {
            this.selectAllCheckbox = true;
            this.addAllToArray();
        } else {
            if (data.code !== 'OPT') {
                this.selectAllCheckbox = false;
            }
            this.showSelectedArray = this.selectedCategories;
        }
        this.selectedRecords.emit(this.showSelectedArray);
        this.emitSelectedvalues.emit(this.selectedCategories);
    }
    /* Removing Options from whole Array and returing the array */
    removeOptionsInArray(array) {
        const codes = array.length ? array.filter((e) => {
            return e.code !== 'OPT';
        }) : [];
        return codes.map(a => a.code);
    }
    /* Highlighting the checked dropdown list */
    getClassByValue(value) {
        return (this.selectedCategories.filter(data => data.code === value).length !== 0);
    }

    /* Inserting the Default Selected records in the Array */
    insertDefaultRecords(valueBond, valueOther) {
        this.selectedCategories = [];
        this.securityDropDownData.forEach((element) => {
            if ((this.defaultSecurityRecords.indexOf(element.code) !== -1) || element.code === valueBond || element.code === valueOther) {
                this.selectedCategories.push(element);
            }
        });
    }

    /*Stock & ETF & Bond & Mutual Fund selected Pushing All*/
    addAllToArray() {
        this.showSelectedArray = [];
        this.showSelectedArray.push(this.defaultAllRecords);
        this.showSelectedArray.sort();
    }

}
