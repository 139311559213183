<div class="sdps-p-horizontal_medium">
  <sdps-notification type="information" class="sdps-m-top_none" *ngIf="showClientMsg">
    <p class="sdps-notification__text" [innerHTML]="getShellConstant.noCientSelected"></p>
  </sdps-notification>
</div>

<div class="sdps-p-horizontal_medium" *ngIf="!showClientMsg">

  <form [formGroup]="addlinkRequestsReportForm">
    <div class="sdps-grid-container sdps-wrap report-dropdown">

      <div class="sdps-row sdps-p-top_x-large">
        <div class="display-flex">
          <app-report-from-to-datepicker (calendarValidation)="calendarValidation($event)" [dateRange]="null"
                                         [initialDateRange]="7" (getEmittedVal)="setDate($event)"
                                         [calendarForm]="addlinkRequestsReportForm"
                                         (throwDateError)="throwDateError($event)" (throwDateRangeError)="throwDateRangeError($event)"></app-report-from-to-datepicker>
        </div>
      </div>

      <div class="sdps-row sdps-p-top_large select-report-type">
        <div class="col-3 sdps-p-left_none sdps-p-right_small" *ngIf="!this.reportsService.accessToOnlyRbs && businessUnit !== sharedConstant.rbsBUText">
          <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (addlinkRequestsReportForm.get('employeeID').value && addlinkRequestsReportForm.get('employeeID').invalid)}">
            <span class="p-float-label">
              <input type="text" name="employeeID" formControlName="employeeID" [maxLength]="35"  emsEmployeeIdPatterncheck emsCharacterLimit
                     [charMaxLimit]="getCommConst.charLimitValues.thirtyFiveMaxLimit" (emsCharacterLimitCheck)="showEmpIdCharLimit = $event" [uppercaseRequired]="true"
                     pInputText/>
              <label [innerHTML]="getShellConstant.reports.presetReports.employeeID"></label>
            </span>
          </div>
          <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small" *ngIf="addlinkRequestsReportForm.get('employeeID').value && addlinkRequestsReportForm.get('employeeID').value.length > showEmpIdCharLimit">{{getShellConstant.reports.characterLimit}}
            <span class="character-limit">{{addlinkRequestsReportForm.get('employeeID').value.length}} /{{getCommConst.charLimitValues.thirtyFiveMaxLimit}}
            </span>
          </p>
        </div>
        <div class="col-3 sdps-p-left_none sdps-p-right_small">
          <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (addlinkRequestsReportForm.get('employeeAccount').value && (addlinkRequestsReportForm.get('employeeAccount').invalid || addlinkRequestsReportForm.get('employeeAccount').value.length < 8)) ,'disabled-cursor-option' :(getOrgId && getOrgId !== 0)}">
            <span class="p-float-label">
              <input type="text" name="employeeAccount" maxlength="8" onkeypress="if(event.which<48 || event.which>57) return false;" emsNumberPatterncheck formControlName="employeeAccount" pInputText/>
              <label [innerHTML]="getShellConstant.reports.presetReports.accountNumber"></label>
            </span>
          </div>
        </div>

        <div class="col-3 sdps-p-left_none sdps-p-right_small">
          <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (addlinkRequestsReportForm.get('masterAccountNumber').value && (addlinkRequestsReportForm.get('masterAccountNumber').invalid || addlinkRequestsReportForm.get('masterAccountNumber').value.length < 8))}">
            <span class="p-float-label">
              <input type="text" name="masterAccountNumber" maxlength="8" onkeypress="if(event.which<48 || event.which>57) return false;" emsNumberPatterncheck formControlName="masterAccountNumber" pInputText/>
              <label [innerHTML]="getShellConstant.reports.presetReports.masterAccountNumber"></label>
            </span>
          </div>
        </div>

        <div class="col-3 sdps-p-left_none sdps-p-right_small">
          <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (addlinkRequestsReportForm.get('employeeLastName').value && addlinkRequestsReportForm.get('employeeLastName').invalid)}">
            <span class="p-float-label">
              <input type="text" *ngIf="businessUnit === sharedConstant.rbsBUText" name="employeeLastName" [maxLength]="50" formControlName="employeeLastName" emsNamePatternCheck emsCharacterLimit
                     (emsCharacterLimitCheck)="showTextboxCharLimit = $event" [charMaxLimit]="getCommConst.charLimitValues.fiftyMaxLimit"
                     pInputText/>
              <input type="text" *ngIf="businessUnit !== sharedConstant.rbsBUText" name="employeeLastName" [maxLength]="50" formControlName="employeeLastName"  emsCharacterLimit
                     (emsCharacterLimitCheck)="showTextboxCharLimit = $event" [charMaxLimit]="getCommConst.charLimitValues.fiftyMaxLimit" [uppercaseRequired]="true"
                     pInputText/>
              <label *ngIf="!this.reportsService.accessToOnlyRbs && businessUnit !== sharedConstant.rbsBUText" [innerHTML]="getShellConstant.reports.presetReports.employeeLastName"></label>
              <label *ngIf="this.reportsService.accessToOnlyRbs || businessUnit === sharedConstant.rbsBUText" [innerHTML]="getShellConstant.reports.presetReports.participantLastName"></label>
            </span>
          </div>
          <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml" *ngIf="addlinkRequestsReportForm.get('employeeLastName').value && addlinkRequestsReportForm.get('employeeLastName').value.length > showTextboxCharLimit">{{getShellConstant.reports.characterLimit}}
            <span class="character-limit">{{addlinkRequestsReportForm.get('employeeLastName').value.length}} /{{getCommConst.charLimitValues.thirtyMaxLimit}}
            </span>
          </p>
        </div>
    </div>

      <div class="sdps-row sdps-p-top_large select-report-type">
        <div class="col-3 sdps-p-left_none sdps-p-right_small sdps-dropdown--lightGrey emtr-dropdown employee-dropdown">
          <p [innerHTML]="getShellConstant.reports.ADDLinkRequestsReport.action">
          </p>
          <p-dropdown optionLabel="label" placeholder=" " [options]="sharedConstant.actionData" formControlName="action"></p-dropdown>
        </div>

        <div
          class="col-3 emtr-dropdown sdps-p-left_none sdps-p-right_small request-list-filter addlink-report-multi-dropdown">
          <p [innerHTML]="getShellConstant.reports.ADDLinkRequestsReport.requestStatus"></p>
          <p-multiSelect appendTo="body" [options]="statusDropdownData" [defaultLabel]="' ' " formControlName="status"
                         [displaySelectedLabel]= "true" [maxSelectedLabels]= "1" [filterPlaceHolder]="'All'"
                         [filter]="true" [showHeader]="true">
          </p-multiSelect>
        </div>

        <div
          class="col-3 emtr-dropdown sdps-p-left_none sdps-p-right_small request-list-filter addlink-report-multi-dropdown">
          <p [innerHTML]="getShellConstant.reports.ADDLinkUsage.source"></p>
          <p-multiSelect appendTo="body" [options]="sourceDropdownData" [defaultLabel]="' ' " formControlName="source"
                         [displaySelectedLabel]= "true" [maxSelectedLabels]= "1" [filterPlaceHolder]="'All'"
                         [filter]="true" [showHeader]="true">
          </p-multiSelect>
        </div>
        <ng-container *ngIf="!this.reportsService.accessToOnlyRbs && businessUnit !== sharedConstant.rbsBUText">
        <div
          class="col-3 sdps-p-left_none sdps-dropdown--lightGrey sdps-p-right_small emtr-dropdown employee-dropdown" *ngIf="sharedService.isInternalApp()">
          <p [innerHTML]="getShellConstant.manageClients.clientDetailsPage.liable"></p>
          <p-dropdown placeholder=" " [options]="sharedConstant.liableData" formControlName="liable"
                      optionLabel="label" ></p-dropdown>
        </div>
        </ng-container>
      </div>
    </div>
  </form>

  <ems-step-wizard-buttons [showCancelBtn]="true" (closeBtn)="cancelReport()" [showRunReportBtn]="true" (runReport)="runReports()"
                           [disableBtn]="addlinkRequestsReportForm.invalid || endDateLessThanStartDate || dateRangeConstarint || dateError || (addlinkRequestsReportForm.get('employeeAccount').value && addlinkRequestsReportForm.get('employeeAccount').value.length < 8) || (addlinkRequestsReportForm.get('masterAccountNumber').value && addlinkRequestsReportForm.get('masterAccountNumber').value.length < 8)"></ems-step-wizard-buttons>
</div>
