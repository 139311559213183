<ems-toast-message *ngIf="removeSuccessMsg" #toastMessage></ems-toast-message>

<ems-confirm-message #confirmationMessage></ems-confirm-message>

<sdps-notification type="error" class="sdps-m-top_none"
                   *ngIf="endDateLessThan || isInValidFromDate || isInValidToDate || filterBtnDisable || filterBtnDisableForTradeSymbol || filterBtnDisableForCusip || specialCharValidation">
  <div class="sdps-notification__text">
    <span [innerHTML]="emtrConstant.rulesConstant.pleaseCorrectFollowingError"></span>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngIf="endDateLessThan"
          [innerHTML]="errorMessage">
      </li>
      <li *ngIf="isInValidFromDate || isInValidToDate"
          [innerHTML]="emtrConstant.inValidDateMessage">
      </li>
      <li *ngIf="filterBtnDisable"
          [innerHTML]="getSharedConstant.textFieldErrorMsg.minCharLimitErrorMsg">
      </li>
      <li *ngIf="filterBtnDisableForTradeSymbol || specialCharValidation"
        [innerHTML]="emtrConstant.rulesConstant.ruleNameMsg.invalidCharacter">
      </li>
      <li *ngIf="filterBtnDisableForCusip"
          [innerHTML]="getSharedConstant.textFieldErrorMsg.cusipShouldContain9Digits">
      </li>
    </ul>
  </div>
</sdps-notification>

<!-- To Download the User Guide for Rules -->
<ems-download-user-guide *ngIf="businessUnit && businessUnit === 'RBS' && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '')"
 [downloadTemplate]="downloadTemplate" [userGuideName]="userGuideName" [gutTrackValue]="ruleGutTrackValue"></ems-download-user-guide>

<h2 class="page-title sdps-m-bottom_large sdps-display-inline-block" [innerHTML]="emtrConstant.rulesConstant.rulesHeader"></h2>

<span *ngIf="ruleService.orgId" class="sdps-m-horizontal_large sdps-display-inline-block client-selection-indicator"
      [ngClass]="this.externalAccess ? 'external-access' : (emtrService.clientInformation | async)?.businessUnit | lowercase"
      [innerHTML]="(emtrService.clientInformation | async)?.k4ClientName">
</span>

<sdps-notification type="information" class="sdps-m-top_none" *ngIf="!ruleService.orgId">
  <div class="sdps-notification__text" [innerHTML]="emtrConstant.rulesConstant.ruleGlobalConst.noCientSelected"></div>
</sdps-notification>

<ems-view-history #viewHistory [historyId]="historyId" [getOrgId]="orgId" [ruleSubType]="ruleSubType" [historyTitle]="ruleName" [historyData]="ruleHistory" [totalElements]="historyRecords" (paginateHistoryList)="getHistoryPagination(historyId,$event)"></ems-view-history>

<div *ngIf="ruleService.orgId">
  <!-- Add New Rule button -->
  <button type="button" class="buttons button-save sdps-pull-left sdps-m-top_small sdps-m-left_small" (click)="navigateCreateRule()"
          *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')">
    <em class="pi pi-plus"></em>
    {{emtrConstant.rulesConstant.addNewRule}}
  </button>

  <!-- Bulk Upload button -->
  <button type="button" class="buttons sdps-pull-left sdps-m-top_small sdps-m-left_small" routerLink="/ems/emtr/rules/rules-bulk-upload"
    routerLinkActive="active" [innerHTML]="emtrConstant.rulesConstant.ruleGlobalConst.bulkUploadRules"
          (click)="sharedService.isDTBtnClicked = true; sharedService.loadDataTrackValue('rule_bulkupload')"
          *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '') && businessUnit !== 'RBS'"></button>

  <ems-table-record-count class="tbl-rec-count" (emitter)="pageObject.pageNo = 0;pageObject.pageSize = $event;setPaginationtoDefault();getRules();"
    [showHeaderIcons]="true"></ems-table-record-count>

  <form [formGroup]="ruleListForm">
    <p-table [columns]="ruleListTableCols" [value]="ruleList" [(selection)]="selectedRules" metaKeySelection="true"
             [lazy]="true" (onLazyLoad)="customSort($event)" [customSort]="true"
             [multiSortMeta]="multiSortMeta" sortMode="multiple" class="common-data-table manage-client-list-table">
      <ng-template pTemplate="header" let-columns>
        <!-- Column header section -->
        <tr class="filter-header-input">
          <th class="table-checkbox-selection filter-icon">
            <a (click)="sharedService.gutTracking('adobe-fl_rulelistfilter|fireonce'); toggleFunction()" class="cursor">
              <em class="pi pi-filter"></em>
            </a>
          </th>
          <th *ngFor="let col of columns" [pSortableColumn]="(col.field !== 'ruleGrpCount')?col.field:''" [pSortableColumnDisabled]="(col.field==='actions' || col.field==='countOfAccountsAssigned')"
              [ngClass]="{'w-15-per': col.header === 'Rule Name'}">
            {{col.header}}
            <p-sortIcon [field]="col.field" *ngIf="(col.field !== 'ruleGrpCount' && col.field!=='actions' && col.field !=='countOfAccountsAssigned')"></p-sortIcon>
          </th>
        </tr>

        <!-- Filter row section -->
        <tr *ngIf="this.toggle" class="filtered-row-input">
          <th *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '')"></th>
          <th *ngFor="let col of columns" class="sdps-p-left_small">
            <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="filter" (keydown.enter)="!(errorMessage || isInValidFromDate || isInValidToDate || filterBtnDisable) ? applyClicked() : ''" onkeydown="return (event.keyCode !== 13);"
                   *ngIf="col.enum === 'textbox'" [ngClass]="{'invalidField': filterBtnDisable}">

            <div *ngIf="col.enum === 'multiselect'"
                 class="col-2 emtr-dropdown timePickerForm request-list-filter request-list-multiselect-font-size request-list-filter-multiselect">
              <p-multiSelect appendTo="body"  formControlName="{{col.field}}" [options]="emtrConstant.ruleFilter[col.field]"
                             [filter]="true" [defaultLabel]="' '" [displaySelectedLabel]="true" [maxSelectedLabels]="1"
                             [showHeader]="true" [filterPlaceHolder]="'All'" [showToggleAll]="false" [filter]="true">
              </p-multiSelect>
            </div>

            <div class="timePickerForm" *ngIf="col.enum === 'dropdown'">
              <p-dropdown appendTo="body" [options]="emtrConstant.ruleFilter[col.field]" placeholder=" "
                          formControlName="{{col.field}}"
                          class="sdps-p-top_small editableTimepicker request-list-header-dropdown" optionLabel="label">
              </p-dropdown>
            </div>

            <div *ngIf="col.field === 'startDt'" class="rule-list-filter-datepicker">
              <tr-datepicker #emtrStartDatepickerComponent class="col-6 sdps-p-around_none" [showAsterisk]="false"
                             (calendarValidationEvent)="calendarValidationEvent($event)"
                             [dateLabel]="emtrConstant.rulesConstant.startDate" [dateForm]="ruleListForm.get('startDt')">
              </tr-datepicker>
            </div>

            <div *ngIf="col.field === 'endDt'" class="rule-list-filter-datepicker">
              <tr-datepicker #emtrEndDatepickerComponent (calendarValidationEvent)="calendarValidationEvent($event)"
                             [dateLabel]="emtrConstant.rulesConstant.endDate" [showErr]="dateErrCss" [showAsterisk]="false"
                             [dateForm]="ruleListForm.get('endDt')" class="col-6 sdps-p-around_none"></tr-datepicker>
            </div>

            <div *ngIf="col.enum === 'dateRange'">
              <ems-date-range-filter #range (submittedDate)="getSelectedDate($event);"></ems-date-range-filter>
            </div>
            <div *ngIf="col.field === 'actions'" class="w-200-px sdps-p-right_none">
              <button class="buttons button-filter" [innerHTML]="emtrConstant.ruleFilter.clearButton"
                [disabled]="!ruleListForm.dirty && !hasValueTradeSymbolField && !hasValueCusipField && !hasGroupPlanValue && !hasValueViewField && !hasInstrumentGroupValue && !isSortingChanged"
                (click)="isClickedClearBtn = true;isApplyFilterClicked? clickedClearButton(isSortingChanged) : resetDefaultForm(isSortingChanged)"></button>
              <button class="buttons button-save button-filter"
                [disabled]="errorMessage || isInValidFromDate || isInValidToDate ||filterBtnDisable || filterBtnDisableForTradeSymbol || filterBtnDisableForCusip || specialCharValidation"
                (click)="applyClicked()" [innerHTML]="emtrConstant.ruleFilter.applyButton"></button>
            </div>
          </th>
        </tr>

        <!-- Sub-header filter row section -->
        <tr *ngIf="this.toggle" class="filtered-row-input">
          <th *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '')"></th>
          <th [attr.colspan]="sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageRule.uuid,'','') ? '8' : '8'" class="sdps-p-around_none">
            <tr-rule-list-sub-header (sendFilterObject)="applyFilter($event)" (setPaginator)="setPaginationtoDefault()"></tr-rule-list-sub-header>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length + 1" class="sdps-text-center sdps-font-weight-bold"
              [innerHTML]="emtrConstant.rulesConstant.autoComplete.noRecords"></td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">

        <tr [pSelectableRow]="rowData">
          <td class="table-checkbox-selection filter-icon"></td>
          <ng-container *ngFor="let col of columns">

          <td *ngIf="col.field !== 'actions'" class="sdps-truncate--ellipses">
            <span *ngIf="col.field === 'name'" (click)="editRule(rowData)" title="{{ rowData[col.field]}}"
                  class="ruleGrpLinks cursor">{{ rowData[col.field]}}</span>
            <span *ngIf="col.field === 'status'">
            <span class="status sdps-p-around-xx-large" ngClass="{{rowData[col.field] | lowercase}}">
              <span class="sdps-m-left_large" [innerHTML]="rowData[col.field] | titlecase"></span>
            </span>
          </span>
            <span *ngIf="col.field === 'ruleType'">
            {{rowData[col.field]}}
          </span>
            <span *ngIf="col.field === 'subType'">
            {{ rowData[col.field] === 'INDSEC' ? 'Individual Security' : rowData[col.field] === 'FNDFMLY' ? 'Fund Family' :'Security Type'}}
          </span>
            <span *ngIf="col.field === 'startDt' || col.field === 'endDt' || col.field === 'updTs'">
            {{(rowData[col.field]) ? moment(rowData[col.field]).format('MM/DD/YYYY') : ''}}

          </span>
          <div *ngIf="col.field === 'countOfAccountsAssigned'">
            {{rowData?.ruleMeta && rowData?.ruleMeta[col.field] ? rowData?.ruleMeta[col.field] : 0}}
          </div>
            <div *ngIf="col.field === 'ruleGrpCount'" class="ruleGrpLinks">
              {{rowData[col.field]}}
            </div>
          </td>
          </ng-container>
          <td>
            <div class="action-menu-dropdown">
              <button type="button" class="cursor sdps-p-around_xx-small"
                      (click)="showActionMenu=true; ruleId=rowData['id']; ruleType=rowData['ruleType'];formData(rowData);"
                      [ngClass]="{'active-menu':ruleId===rowData['id']}">
                <em class="pi pi-ellipsis-h sdps-p-around_xx-small"></em>
              </button>
              <p-menu [model]="actionMenu"
                      *ngIf="ruleId===rowData['id']"
                      class="menu-dropdown" (click)="onActionMenuClick($event, rowData)"></p-menu>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>
  <p-paginator #p class="common-pagination"  [rows]="pageObject.pageSize" [totalRecords]="totalRules" (onPageChange)="paginate($event);"></p-paginator>
</div>
